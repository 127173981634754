import React, {useState} from "react";
import '../../styles/components/details/QuestionPreviewCard.css';
import SlAnimation from '@shoelace-style/shoelace/dist/react/animation';
import {maskString, modImageUrl} from "../../utils/StringUtils";

export function QuestionPreviewCard({data, image, index, onClick}) {
    const [playAnimation, setPlayAnimation] = useState(false);
    //console.log("TriviaQuizDetailCardOne data: ", data);

    const clickAction = () => {
        setPlayAnimation(true);
        if (onClick) onClick();
    }

    return (
        <SlAnimation name="pulse" duration={1000} iterations={1} play={playAnimation}
                     onSlFinish={() => setPlayAnimation(false)}>
            <div className="trivia-quiz-detail-card-one" onClick={clickAction}>
                <img src={`${modImageUrl(data?.image ? data?.image : image)}`} alt="quiz" className="trivia-quiz-detail-card-one-image"/>
                <div className="trivia-quiz-detail-card-one-content">
                    <div className="trivia-quiz-detail-card-one-months">{index + 1}</div>
                    <div className="question-preview-card-title">{maskString(data.Q, 25)}</div>
                </div>
            </div>
        </SlAnimation>
    );
}