import React from 'react';
import '../../styles/components/cards/TriviaAvatarCard.css';
import {modImageUrl} from "../../utils/StringUtils";

export function TriviaAvatarCard({data, actionName, actionClick, className}) {
    //console.log('TriviaAvatarCard: data ', data);
    return (
        <div className="quiz-details-author-parent">
            <div className="quiz-details-author-left">
                <img src={modImageUrl(data?.authorUrl)} alt="avatar" className="quiz-details-author-avatar"/>
                <div className="quiz-details-author-text-parent">
                    <div className="quiz-details-author">{data?.author}</div>
                    <div className="quiz-details-author-username">@{data?.authorSlug}</div>
                </div>
            </div>
            <span className={`quiz-details-author-follow ${data?.following ? 'following': ''}`}>{actionName}</span>
        </div>
    );
}