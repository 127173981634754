import React, {useEffect, useState} from 'react'
import '../styles/screens/SearchScreen.css'
import {QuizSearchBar} from "../components/QuizSearchBar";
import {TriviaPillHeader} from "../components/headers/TriviaPillHeader";
import {ArrowLeftIcon} from "@radix-ui/react-icons";
import {useNavigate} from "react-router-dom";
import {getAuthor, getCollection, getQuiz} from "../utils/ApiManager";
import {TriviaQuizDetailCardOne} from "../components/details/TriviaQuizDetailCardOne";
import {TriviaAvatarCard} from "../components/cards/TriviaAvatarCard";
import {TriviaCollection} from "../components/TriviaCollection";


export function SearchScreen() {
    const [activeSource, setActiveSource] = useState('quiz');
    const [quizItems, setQuizItems] = useState([]);
    const [peopleItems, setPeopleItems] = useState([]);
    const [categoryItems, setCategoryItems] = useState([]);
    let navigate = useNavigate()

    useEffect(() => {
        getQuiz().then((response) => {
            setQuizItems(response);
            //console.log('response', response);
        }, (error) => {
            console.error(error)
        });
        getAuthor().then((response) => {
            setPeopleItems(response);
            //console.log('response', response);
        }, (error) => {
            console.error(error)
        });
        getCollection().then((response) => {
            setCategoryItems(response);
            //console.log('response', response);
        }, (error) => {
            console.error(error)
        });
    }, []);

    const onBack = () => {
        navigate(-1)
    }

    const onCollectionClick = (collection) => {
        navigate('/collection/' + collection.slug, {state: {collection: collection}})
    }

    const renderQuiz = () => {
        return (
            <div className="viewall-content">
                {quizItems.map((quiz, index) => (
                    <TriviaQuizDetailCardOne data={quiz} key={index} className={"viewall-body"}/>
                ))}
            </div>
        )
    }
    const renderPeople = () => {
        return (
            <div className="viewall-content">
                {peopleItems.map((quiz, index) => (
                    <TriviaAvatarCard data={quiz} key={index} actionName={`Follow`} className={"viewall-body"}/>
                ))}
            </div>
        )
    }
    const renderCategories = () => {
        return (
            <div className="viewall-content catgories">
                {categoryItems.map((quiz, index) => (
                    <TriviaCollection
                        data={quiz}
                        key={index}
                        className={"viewall-body"}
                        onClick={() => onCollectionClick(quiz)}/>
                ))}
            </div>
        )
    }

    const renderContent = () => {
        switch (activeSource) {
            case 'quiz':
                return renderQuiz();
            case 'people':
                return renderPeople();
            case 'categories':
                return renderCategories();
            default:
                return renderQuiz();
        }
    }

    return (
        <div className={`search-screen-container`}>
            <div className={`search-screen-searchbar-container`}>
                <ArrowLeftIcon className={`search-screen-back-button`} onClick={onBack}/>
                <QuizSearchBar placeholder={`Search quiz, people, or collection`}/>
            </div>
            <TriviaPillHeader
                options={['Quiz', 'People', 'Categories']}
                defaultValue={`quiz`}
                className={`search-screen-pill-header`}
                activeSource={activeSource}
                setActiveSource={setActiveSource}/>
            <div className={`search-screen-content-container`}>
                {renderContent()}
            </div>
        </div>
    )
}