import {PodiumUser} from "./PodiumUser";
import '../styles/components/TopThreeUsers.css';
import {dummyFemaleImage, dummyImage, dummyMaleImage} from "../utils/StringUtils";
import React from "react";

export function TopThreeUsers() {
    return (
        <div className={`top-three-users-container`}>
            <div className={`top-three-users-podium`}>
                <PodiumUser displayName={'John Doe'}
                            photoURL={dummyImage}
                            points={700}
                            className={`podium-two`}/>
                <PodiumUser displayName={'John Doe'}
                            photoURL={dummyFemaleImage}
                            points={945}
                            className={`podium-one`}/>
                <PodiumUser displayName={'John Doe'}
                            photoURL={dummyMaleImage}
                            points={560}
                            className={`podium-third`}/>
            </div>

            <div className={`top-three-users-stand`}></div>
        </div>
    );
}