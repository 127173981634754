import React, {useEffect, useState} from 'react';
import '../styles/screens/TriviaScoreboard.css';
import {TriviaScoreboardHeader} from "../components/TriviaScoreboardHeader";
import {ScoreboardUser} from "../components/ScoreboardUser";
import SlAnimation from "@shoelace-style/shoelace/dist/react/animation";
import {useNavigate} from "react-router-dom";
import {getScoreBoard} from "../utils/FirebaseManager";

export function TriviaScoreboard({quizId, uid}) {
    const [activeAnimation, setActiveAnimation] = useState(null);
    const [scoreData, setScoreData] = useState([]);
    let navigate = useNavigate();
    function handleOptionClick(option) {
        setActiveAnimation(option);
        navigate(-2)
    }

    useEffect(() => {
        console.log('Quiz ID: ', quizId);
        setTimeout(() => {
            getScoreBoard(quizId, setScoreData);
        }, 3000);
    }, []);

    return (
        <div className={`trivia-scoreboard-container`}>
            <TriviaScoreboardHeader className={`trivia-scoreboard-header`}/>
            <div className={`trivia-scoreboard-content`}>
                {scoreData.map((item, index) => (
                    <ScoreboardUser data={item} uid={uid} key={index}/>
                ))}
            </div>
            <div className={`trivia-scoreboard-footer`}>
                <SlAnimation name="pulse" duration={500} iterations={1} play={activeAnimation !== null}
                             onSlFinish={() => setActiveAnimation(null)}>
                    <button className="trivia-scoreboard-footer-button" onClick={() => handleOptionClick(1)}>Next</button>
                </SlAnimation>
            </div>
        </div>
    )
}