import React, {useEffect, useState} from 'react';
import '../styles/screens/TriviaCover.css';
import {TriviaHeaderThree} from "../components/headers/TriviaHeaderThree";
import {TriviaSearchBar} from "../components/TriviaSearchBar";


export function TriviaCover({setSelectedImage}) {
    const [activeSource, setActiveSource] = React.useState('online-media');
    const [images, setImages] = useState([]);
    const sources = ['Online Media', 'Gallery', 'Camera'];

    function handleImageSelect(image) {
        setSelectedImage(image);
    }

    return (
        <div className="trivia-cover-container">
            <TriviaHeaderThree title={`Add Cover Image`} className={`trivia-cover-header`}/>
            <TriviaSearchBar placeholder={`Search for media online here`} className={`trivia-cover-search-bar`} setImages={setImages}/>
            <div className="trivia-cover-source-list">
                {sources.map((source, index) => {
                    return (
                        <div key={index}
                             className={`trivia-cover-source ${activeSource === source.toLowerCase().replace(' ', '-') ? 'active' : ''}`}
                             onClick={() => setActiveSource(source.toLowerCase().replace(' ', '-'))}>{source}</div>
                    )
                })}
            </div>
            <div className={`trivia-cover-source-content ${activeSource}`}>
                <div className="trivia-cover-source-content-grid">
                    {images.map((item, index) => {
                        return (
                            <img key={index} src={item.medium} className="trivia-cover-source-content-item" alt="cover image" onClick={() => handleImageSelect(item)}/>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}