import '../styles/screens/Forgot.css';
import {ArrowLeftIcon} from "@radix-ui/react-icons";
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import {useNavigate} from "react-router-dom";

export function Forgot() {

    let navigate = useNavigate();

    const onBack = () => {
        navigate(-1); // Step 3: Use navigate to go back
    }

    const bodyHeader = () => {
        return (
            <div>
                <h1 className="create-title title1">Forgot Password 🔑</h1>
            </div>
        );
    }

    const renderStep1 = () => {
        return (
            <div className="create-body">
                {bodyHeader()}
                <div className="create-input-container">
                    <SlInput label="Email" className="create-input-name" type={"email"}/>

                </div>
            </div>
        );
    }

    return (
        <div className="create-parent">
            <div className="create-header">
                <ArrowLeftIcon className="create-back-button" onClick={onBack}/>
            </div>
            <div className="create-body-top">
                {renderStep1()}
            </div>
            <div className="create-body-bottom">
                <button className="intro-button sign-up-button">Continue</button>
            </div>
        </div>
    );
}