import React from "react";
import '../../styles/components/headers/TriviaHeaderOne.css';
import {useNavigate} from "react-router-dom";

export function TriviaHeaderOne({title, actionTitle, isQuestion = false, type='quiz', data}) {
    let navigate = useNavigate();

    const viewAll = () => {
        //console.log('View All');
        navigate('/view-all',
            {
                state: {
                    title: title,
                    actionTitle: actionTitle,
                    type: type,
                    data: data
                }
            }
        )
    }

    return (
        <div className={`trivia-header-one-container`}>
            <div className={`trivia-header-one-title ${isQuestion ? 'question' : ''}`}>{title}</div>
            <div className="trivia-header-one-action" onClick={viewAll}>{actionTitle}</div>
        </div>
    )
}