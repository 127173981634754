import React, {useState} from 'react';
import '../styles/components/TriviaAvatar.css';
import SlAnimation from "@shoelace-style/shoelace/dist/react/animation";
import {modImageUrl} from "../utils/StringUtils";

export function TriviaAvatar({data, onClick}) {
    //console.log("TriviaAvatar.js: ", data);
    const [playAnimation, setPlayAnimation] = useState(false);

    const clickAction = () => {
        setPlayAnimation(true);
        if (onClick) onClick();
    }

    return (
        <SlAnimation name="pulse" duration={500} iterations={1} play={playAnimation}
                     onSlFinish={() => setPlayAnimation(false)}>
            <div className={`trivia-avatar-container`} onClick={clickAction}>
                <img src={modImageUrl(data?.authorUrl)} className="trivia-avatar-image"/>
                <div className="trivia-avatar-username">{data?.author}</div>
            </div>
        </SlAnimation>
    )
}