import React, {useEffect, useState} from 'react';
import '../../styles/screens/details/QuizDetails.css';
import {TriviaHeaderTwo} from "../../components/headers/TriviaHeaderTwo";
import {useLocation, useNavigate} from "react-router-dom";
import {TriviaHeaderOne} from "../../components/headers/TriviaHeaderOne";
import {formatNumber, modImageUrl} from "../../utils/StringUtils";
import {QuestionPreviewCard} from "../../components/details/QuestionPreviewCard";
import {getQuizStats} from "../../utils/FirebaseManager";

export function QuizDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { data } = location.state || {};
    const id = location.pathname.split('/')[2];
    const [gameStats, setGameStats] = useState({ played: 0, favorited: 0, shared: 0 });

    useEffect(() => {
        getQuizStats(id).then((response) => {
            setGameStats(response);
        });
    }, [id]); // Ensuring id is a dependency in case it changes
    useEffect(() => {
        // Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1
        const isIphone = /iPhone/.test(navigator.userAgent);
        //console.log('isIphone: ', isIphone);
        //const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        //console.log('navigator.userAgent: ', navigator.userAgent);
        if (isIphone) {
            document.querySelector('.quiz-details-container').classList.add('iphone');
        }
    }, []);

    const playSolo = () => {
        navigate(`/game/${id}`, { state: { data } });
    };

    return (
        <div className="quiz-details-container">
            <TriviaHeaderTwo className="quiz-details-header" />
            <div className="quiz-details-content">
                <img src={modImageUrl(data?.image)} alt="" className="quiz-details-image" />
                <p className="quiz-details-title">{data?.title}</p>
                <div className="quiz-details-divider" />
                <div className="quiz-details-quiz-stats">
                    {['Questions', 'Played', 'Favorited', 'Shared'].map((stat, index) => (
                        <React.Fragment key={index}>
                            <div className="quiz-details-quiz-stats-item">
                                <p>{stat === 'Questions' ? data?.count : formatNumber(gameStats[stat.toLowerCase()])}</p>
                                <p>{stat}</p>
                            </div>
                            {index < 3 && <div className="quiz-details-quiz-separator" />}
                        </React.Fragment>
                    ))}
                </div>
                <div className="quiz-details-divider" />
                <div className="quiz-details-author-parent">
                    <div className="quiz-details-author-left">
                        <img src={modImageUrl(data?.authorUrl)} alt="avatar" className="quiz-details-author-avatar" />
                        <div className="quiz-details-author-text-parent">
                            <div className="quiz-details-author">{data?.author}</div>
                            <div className="quiz-details-author-username">@{data?.authorSlug}</div>
                        </div>
                    </div>
                    <span className="quiz-details-author-follow">Follow</span>
                </div>
                <p className="quiz-details-description title">Description</p>
                <p className="quiz-details-description content">{data?.description}</p>
                <div className="quiz-details-difficulty">
                    <p className="quiz-details-difficulty-title">Difficulty:</p>
                    <span className={`quiz-details-difficulty-value ${data?.difficulty.toString().toLowerCase()}`}>{data?.difficulty}</span>
                </div>
                <div className="quiz-details-tags-container">
                    <p className="quiz-details-tags title">Tags:</p>
                    <div className="quiz-details-tags">
                        {data?.tags?.map((tag, index) => (
                            <p className="quiz-details-tag" key={index}>{tag}</p>
                        ))}
                    </div>
                </div>
                <TriviaHeaderOne title={`Questions (${data?.count})`} actionTitle="View All" isQuestion={true} />
                <div className="quiz-details-questions">
                    {data?.questions?.map((quiz, index) => (
                        <QuestionPreviewCard data={quiz} image={data?.image} index={index} key={index} />
                    ))}
                </div>
            </div>
            <div className="quiz-details-footer">
                <button className="intro-button play play-solo" onClick={playSolo}>Play Solo</button>
                <button className="intro-button play play-buddy">Play with Friends</button>
            </div>
        </div>
    );
}