import '../styles/screens/Create.css';
import {ArrowLeftIcon} from "@radix-ui/react-icons";
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlCheckbox from '@shoelace-style/shoelace/dist/react/checkbox';
import {useNavigate} from "react-router-dom";

export function Create() {

    let navigate = useNavigate();

    const onBack = () => {
        navigate(-1); // Step 3: Use navigate to go back
    }

    const bodyHeader = () => {
        return (
            <div>
                <h1 className="create-title title1">Create an account ✏️</h1>
                <h3 className="create-title title2">Please enter your username, email<br/> address and password.</h3>
                <h3 className="create-title title3">If you forget it then you have to do <br />forgot password.</h3>
            </div>
        );
    }

    const bodyHeader2 = () => {
        return (
            <div>
                <h1 className="create-title title1">Create an account ✏️</h1>
                <h3 className="create-title title2">Please complete your profile.</h3>
                <h3 className="create-title title3">Don't worry, your data will remain private and <br/> only you can
                    see it.</h3>
            </div>
        );
    }

    const renderStep1 = () => {
        return (
            <div className="create-body">
                {bodyHeader()}
                <SlInput label="Full Name" className="create-input-name" type={"text"}/>
                <SlInput label="Date of Birth" type={"date"} className="create-input-dob"/>
            </div>
        );
    }

    const renderStep2 = () => {
        return (
            <div className="create-body">
                {bodyHeader()}
                <div className="create-input-container">
                    <SlInput label="Username" className="create-input-name" type={"text"}/>
                    <SlInput label="Email" className="create-input-name" type={"email"}/>
                    <SlInput label="Password" className="create-input-name" type={"password"}/>
                    <SlCheckbox className="create-input-remember">Remember Me</SlCheckbox>
                    <div className="create-divider-or-container">
                        <div className="create-divider"/>
                        <div className="create-divider-or">or</div>
                        <div className="create-divider"/>
                    </div>
                    <button className="sso-button google-button">Continue with Google</button>
                    <button className="sso-button facebook-button">Continue with Facebook</button>
                </div>
            </div>
        );
    }

    return (
        <div className="create-parent">
            <div className="create-header">
                <ArrowLeftIcon className="create-back-button" onClick={onBack}/>
            </div>
            <div className="create-body-top">
                {renderStep2()}
            </div>
            <div className="create-body-bottom">
                <button className="intro-button sign-up-button">Sign Up</button>
            </div>
        </div>
    );
}