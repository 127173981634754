import React from 'react';
import '../styles/components/QuestionTypeGrid.css';
import QuizImg from "../assets/images/questions/quiz.png";
import TrueOrFalse from "../assets/images/questions/trueorfalse.png";
import Puzzle from "../assets/images/questions/puzzle.png";
import TypeAnswer from "../assets/images/questions/type-answer.png";
import QuizAudio from "../assets/images/questions/quiz-audio.png";
import Slider from "../assets/images/questions/slider.png";
import Checkbox from "../assets/images/questions/checkbox.png";
import SayTheWord from "../assets/images/questions/audio.png";
import Poll from "../assets/images/questions/poll.png";
import DropPin from "../assets/images/questions/drop-pin.png";
import {useNavigate} from "react-router-dom";

const questionData = [
    { title: 'Quiz', icon: <img src={QuizImg} alt="Quiz"/> },
    { title: 'True or False', icon: <img src={TrueOrFalse} alt="True or False"/> },
    { title: 'Puzzle', icon: <img src={Puzzle} alt="Puzzle"/> },
    { title: 'Type Answer', icon: <img src={TypeAnswer} alt="Type Answer"/> },
    { title: 'Quiz + Audio', icon: <img src={QuizAudio} alt="Quiz + Audio"/> },
    { title: 'Slider', icon: <img src={Slider} alt="Slider"/> },
    { title: 'Checkbox', icon: <img src={Checkbox} alt="Checkbox"/> },
    { title: 'Say the word', icon: <img src={SayTheWord} alt="Say the Word"/> },
    { title: 'Poll', icon: <img src={Poll} alt="Poll"/> },
    { title: 'Drop Pin', icon: <img src={DropPin} alt="Drop Pin"/> }
];


export function QuestionTypeGrid() {
    let navigate = useNavigate();
    const onClick = () => {
        navigate('/question')
    }
    return (
        <div className="question-type-grid">
            {questionData.map((item, index) => (
                <QuestionItem key={index} icon={item.icon} title={item.title} onClick={onClick} />
            ))}
        </div>
    );
}

export function QuestionItem({icon, title, onClick}) {
    return (
        <div className="question-type-grid-item" onClick={onClick}>
            <div className="question-type-grid-item-content">
                <div className="question-type-grid-item-content-description">{icon}</div>
                <div className="question-type-grid-item-content-title">{title}</div>
            </div>
        </div>
    )
}