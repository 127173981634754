import React, {useState} from "react"
import "../styles/components/BottomBarTwo.css"
import HomeIcon from '../assets/images/homepage/home.png'
import CreateIcon from '../assets/images/homepage/create.png'
import LibraryIcon from '../assets/images/homepage/library.png'
import PersonIcon from '../assets/images/homepage/person.png'
import AppIcon from '../assets/images/logo192.webp'

export default function BottomBarTwo({activeTab, setActiveTab}) {
    const options = [
        {
            title: 'Home',
            icon: HomeIcon
        },
        {
            title: 'Library',
            icon: LibraryIcon
        },
        {
            title: 'Join',
            icon: AppIcon
        },
        {
            title: 'Create',
            icon: CreateIcon
        },
        {
            title: 'Profile',
            icon: PersonIcon
        }
    ]

    const clickTab = (index) => {
        setActiveTab(index);
    }

    return (
        <div className="bottom-bar-container">
            {Array.from({length: 5}, (_, i) => (
                <div className={`bottom-bar-item ${activeTab === i ? 'active' : ''}`} onClick={()=>clickTab(i)} key={i}>
                    <img
                        src={options[i].icon}
                        alt="Bottom Bar Item"
                        className="bottom-bar-icon"/>
                    <p className={`bottom-bar-title ${activeTab === i ? '' : 'hidden'}`}>{options[i].title}</p>
                </div>
            ))}
        </div>
    );
}
