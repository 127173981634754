import '../styles/screens/Homepage.css';
import {TriviaBottomBar} from "../components/TriviaBottomBar";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {Home} from "./fragments/Home";
import {Library} from "./fragments/Library";
import {Join} from "./fragments/Join";
import {CreateQuiz} from "./fragments/CreateQuiz";
import {Profile} from "./fragments/Profile";


export function Homepage() {
    const [bottomIndex, setBottomIndex] = useState(0);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    let navigate = useNavigate();

    /*useEffect(() => {
        console.log(bottomIndex)
    }, [bottomIndex])*/

    /*useEffect(() => {
        const auth = getAuth();
        // This listener gets called whenever the user's sign-in state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                // User is signed in
                setUser(currentUser);
                setUid(currentUser.uid);
                navigate('/home')
                //console.log('User infox:', currentUser, currentUser.displayName);
            } else {
                // User is signed out
                setUser(null);
                console.log('User is signed out');
                navigate('/')
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []);*/

    const renderTab = () => {
        switch (bottomIndex) {
            case 0:
                return <Home uid={uid}/>;
            case 1:
                return <Library uid={uid}/>;
            case 2:
                return <Join uid={uid}/>;
            case 3:
                return <CreateQuiz uid={uid}/>;
            default:
                return <Profile uid={uid}/>;
        }
    }

    return (
        <div className="homepage-parent">
            <div className="homepage-body">
                {renderTab()}
            </div>
            <div className="homepage-footer">
                <TriviaBottomBar bottomIndex={bottomIndex} setBottomIndex={setBottomIndex} className="homepage-bottom-bar"/>
            </div>
        </div>
    );
}