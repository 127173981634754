import '../../styles/components/headers/TriviaHeaderFour.css';
import {ArrowLeftIcon, } from "@radix-ui/react-icons";
import {useNavigate} from "react-router-dom";
import ActivityIcon from "../../assets/images/profile/activity.png";
import SendIcon from "../../assets/images/profile/send.png";
import SettingsIcon from "../../assets/images/profile/setting.png";
import AppIcon from "../../assets/images/logo192.webp";

export function TriviaHeaderFour({title, className, backAction}) {
    let navigate = useNavigate();
    const onBack = () => {
        if (backAction) {
            backAction();
        } else {
            navigate(-1);
        }
    }

    const openSettings = () => {
        navigate('/settings');
    }

    return (
        <div className={`profile-header ${className}`}>
            <div className="profile-header-left">
                <img src={AppIcon} className="profile-left-icon" onClick={onBack}/>
                <p className="profile-name">{title}</p>
            </div>
            <div className="profile-header-right">
                <img src={SendIcon} className="profile-header-icon share-icon"/>
                <img src={ActivityIcon} className="profile-header-icon search-icon"/>
                <img src={SettingsIcon} className="profile-header-icon bell-icon" onClick={openSettings}/>
            </div>
        </div>
    );
}