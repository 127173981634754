import React, {useEffect, useState} from 'react';
import '../styles/components/ScoreboardUser.css';
import {timeAgo} from "../utils/StringUtils";

export function ScoreboardUser({data, uid}) {
    //console.log('Scoreboard User: ', data?.uid);
    //console.log('Scoreboard User UID: ', uid);

    return (
        <div className={`scoreboard-user-container ${uid === data?.uid ? 'user' : ''}`}>
            <div className={`scoreboard-user-image-container`}>
                <img className={`scoreboard-user-image`} src={data?.image} alt="profile picture"/>
                <p className={`scoreboard-user-name`}>{data?.name}</p>
            </div>
            <p className={`scoreboard-user-points`}>{data?.score}pts</p>
            <p className={`scoreboard-user-ranking`}>{timeAgo(data?.time)}</p>
        </div>
    );
}