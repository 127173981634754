import '../../styles/components/headers/TriviaHeaderThree.css';
import {Cross1Icon} from "@radix-ui/react-icons";
import {useNavigate} from "react-router-dom";

export function TriviaHeaderThree({title, className, backAction}) {
    let navigate = useNavigate();
    const onBack = () => {
        if (backAction) {
            backAction();
        } else {
            navigate(-1);
        }
    }

    return (
        <div className={`homepage-header ${className} trivia-header-three-container`}>
            <div className="homepage-header-left">
                <Cross1Icon className="homepage-left-icon trivia-header-three" onClick={onBack}/>
                <p className="homepage-app-name trivia-header-three-title">{title}</p>
            </div>
        </div>
    );
}