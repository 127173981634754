import React from 'react';
import '../../styles/components/headers/SortHeaderOne.css';
import {SwapVert} from "@mui/icons-material";


export function SortHeaderOne({title, filterTitle}) {
    return (
        <div className="sort-header-one">
            <h3 className="sort-header-one-title">{title}</h3>
            <div className="sort-header-one-filter">
                <h3 className="sort-header-one-action">{filterTitle}</h3>
                <SwapVert className="sort-header-one-icon"/>
            </div>
        </div>
    )
}