import React from 'react';
import '../styles/screens/Settings.css';
import {TriviaHeaderTwo} from "../components/headers/TriviaHeaderTwo";
import {TriviaCardOne} from "../components/cards/TriviaCardOne";
import {TriviaVerticalList} from "../components/lists/TriviaVerticalList";
import {SettingItems} from "../components/SettingItems";
import {useNavigate} from "react-router-dom";

export function Settings() {
    const iconTitle = ['Personal Info', 'Notifications', 'Music & Effects', 'Security', 'Help Center', 'About 3Via', 'Logout']
    const icons = []

    let navigate = useNavigate();
    const goToPersonalInfo = () => {
        navigate('/personal-info');
    }

    return (
        <div className={`settings-container`}>
            <TriviaHeaderTwo title={`Settings`}/>
            <TriviaVerticalList>
                <TriviaCardOne title={`Play quizzes without ads and restrictions`} actionTitle={`GO PREMIUM`}/>
                <SettingItems title={iconTitle[0]} onClick={goToPersonalInfo}/>
                {iconTitle.map((value, index) => {
                    return <SettingItems title={value}/>
                })}
            </TriviaVerticalList>
        </div>
    );
}