import React, {useState} from 'react';
import '../styles/components/TriviaSearchBar.css';
import {MagnifyingGlassIcon} from "@radix-ui/react-icons";
import {debounce} from "@mui/material";
import {getImages} from "../utils/ApiManager";

export function TriviaSearchBar({ placeholder, setImages, className }) {
    const [timeoutId, setTimeoutId] = useState(null);

    function searchImages(query) {
        getImages(query).then(
            (response) => {
                console.log(response);
                if (!response || response.length === 0) return;
                setImages(response);
            },
            (error) => {
                console.error(error);
            }
        );
    }

    // Listen for input changes with a delay of 2 seconds and then trigger the getImages function
    const handleSearch = (e) => {
        const searchValue = e.target.value;
        console.log(searchValue);

        // Clear any previous pending trigger
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set a new pending trigger
        const newTimeoutId = setTimeout(() => {
            searchImages(searchValue);
        }, 2000);

        setTimeoutId(newTimeoutId);
    };

    return (
        <div className={`search-bar-container ${className}`}>
            <MagnifyingGlassIcon className="search-bar-icon" />
            <input type="search" className="search-bar-input" placeholder={placeholder} onChange={handleSearch}/>
        </div>
    );
}