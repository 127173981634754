import '../../styles/components/headers/JoinHeader.css';
import {Cross1Icon} from "@radix-ui/react-icons";
import {useNavigate} from "react-router-dom";

export function JoinHeader({title, className, backAction}) {
    let navigate = useNavigate();
    const onBack = () => {
        if (backAction) {
            backAction();
        } else {
            navigate(-1);
        }
    }

    return (
        <div className={`join-header-header ${className}`}>
            <Cross1Icon className="join-header-left-icon" onClick={onBack}/>
            <p className="join-header-name">{title}</p>
            <div className="join-header-right">
            </div>
        </div>
    );
}