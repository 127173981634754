import React from 'react';
import '../../styles/components/headers/TriviaSessionHeader.css';
import {DotsHorizontalIcon} from "@radix-ui/react-icons";

export function TriviaSessionHeader({questionNumber, points=0}) {
    return (
        <div className="trivia-session-header">
            <p className="trivia-session-header-question">{questionNumber}</p>
            <p className="trivia-session-header-title">{points} pts</p>
            <DotsHorizontalIcon className="trivia-session-header-menu"/>
        </div>
    );
}