import '../styles/screens/Login.css';
import {ArrowLeftIcon} from "@radix-ui/react-icons";
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlCheckbox from '@shoelace-style/shoelace/dist/react/checkbox';
import {useNavigate} from "react-router-dom";

export function Login() {
    let navigate = useNavigate();

    const onBack = () => {
        navigate(-1); // Step 3: Use navigate to go back
    }

    const resetPassword = () => {
        navigate('/reset');
    }

    const bodyHeader = () => {
        return (
            <div>
                <h1 className="create-title title1">Hello there 👋️</h1>
            </div>
        );
    }

    const renderStep1 = () => {
        return (
            <div className="create-body">
                {bodyHeader()}
                <div className="create-input-container">
                    <SlInput label="Email" className="create-input-name" type={"email"}/>
                    <SlInput label="Password" className="create-input-name" type={"password"}/>
                    <SlCheckbox className="create-input-remember">Remember Me</SlCheckbox>
                    <div className="create-forgot-password" onClick={resetPassword}>Forgot Password?</div>
                    <div className="create-divider-or-container">
                        <div className="create-divider"/>
                        <div className="create-divider-or">or</div>
                        <div className="create-divider"/>
                    </div>
                    <button className="sso-button google-button">Continue with Google</button>
                    <button className="sso-button facebook-button">Continue with Facebook</button>
                </div>
            </div>
        );
    }

    return (
        <div className="create-parent">
            <div className="create-header">
                <ArrowLeftIcon className="create-back-button" onClick={onBack}/>
            </div>
            <div className="create-body-top">
                {renderStep1()}
            </div>
            <div className="create-body-bottom">
                <button className="intro-button sign-up-button">Sign Up</button>
            </div>
        </div>
    );
}