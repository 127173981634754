import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Intro} from "./screens/Intro";
import {Create} from "./screens/Create";
import {Login} from "./screens/Login";
import {Forgot} from "./screens/Forgot";
import {Homepage} from "./screens/Homepage";
import {ViewAll} from "./screens/details/ViewAll";
import {QuizDetails} from "./screens/details/QuizDetails";
import {TriviaSession} from "./screens/TriviaSession";
import {TriviaFinalScoreboard} from "./screens/TriviaFinalScoreboard";
import {TriviaCreateSession} from "./screens/TriviaCreateSession";
import {Playground} from "./components/Playground";
import {Settings} from "./screens/Settings";
import {PersonalInfo} from "./screens/settings/PersonalInfo";
import {SearchScreen} from "./screens/SearchScreen";
import {CollectionDetail} from "./screens/details/CollectionDetail";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/intro" element={<Intro/>}/>
                <Route path="/register" element={<Create/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/reset" element={<Forgot/>}/>
                <Route path="/" element={<Homepage/>}/>
                <Route path="/view-all" element={<ViewAll/>}/>
                <Route path="/quiz/:id" element={<QuizDetails/>}/>
                <Route path="/game/:id" element={<TriviaSession/>}/>
                <Route path="/results" element={<TriviaFinalScoreboard/>}/>
                <Route path="/create" element={<Create/>}/>
                <Route path="/question" element={<TriviaCreateSession/>}/>
                <Route path="/play" element={<Playground/>}/>
                <Route path="/play/:id" element={<Playground/>}/>
                <Route path="/settings" element={<Settings/>}/>
                <Route path="/personal-info" element={<PersonalInfo/>}/>
                <Route path="/search" element={<SearchScreen/>}/>
                <Route path="/collection/:id" element={<CollectionDetail/>}/>
                <Route path="*" element={<Intro/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
