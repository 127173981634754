import React, {useState} from 'react';
import '../../styles/components/questions-active/TriviaQuizGridMCQ.css';
import SlAnimation from "@shoelace-style/shoelace/dist/react/animation";

export function TriviaQuizGridMCQ({
                                      data,
                                      answer,
                                      question,
                                      setSelectedOption,
                                      edit = false,
                                      duration = 500,
                                      isFinalQuestion = false
                                  }) {
    //console.log('TriviaQuizGridMCQ Answer: ', answer);
    const [state, setState] = useState({
        activeAnimation: null,
        activateResult: false,
        previousQuestion: null,
        enabled: true
    });

    function handleOptionClick(option, selectedAnswer) {
        //console.log('handleOptionClick: finalQuestion: ', isFinalQuestion);
        if (!state.enabled || isFinalQuestion) return;
        setState(prev => ({
            ...prev, activeAnimation: option,
            activateResult: true,
            previousQuestion: question,
            enabled: false
        }));
        setSelectedOption(selectedAnswer);
        if (isFinalQuestion) return;
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                activateResult: false,
                enabled: true
            }));
        }, 2000);
    }

    function renderResultMarker(option) {
        if (!state.activateResult) return null;
        if (state.previousQuestion !== question) return null;
        //console.log('renderResultMarker: ', option);
        //console.log('Answer: ', answer, 'Data: ', data[option - 1]);
        return null;
        /*return (answer === data[option - 1] ? (<Check className={`mcq-option-result-icon correct`}/>) : (
            <Close className={`mcq-option-result-icon wrong`}/>))*/
    }

    function renderButton(option) {
        //console.log('answer === data[option - 1] && !isFinalQuestion: ', answer === data[option - 1] && !isFinalQuestion);
        return (<SlAnimation
            key={option}
            name="pulse"
            duration={duration}
            iterations={1}
            play={state.activeAnimation === option}
            onSlFinish={() => {
                setState(prev => ({...prev, activeAnimation: null}));
            }}>
            <div>
                <button
                    className={`mcq-option 
                    ${['one', 'two', 'three', 'four'][option - 1]} 
                    ${(answer === data[option - 1] && !isFinalQuestion) ? 'correct' : 'wrong'} 
                    ${state.activateResult ? 'result' : ''} 
                    ${state.enabled ? '' : 'disabled'}`}
                    onClick={() => handleOptionClick(option, data[option - 1])}>
                    {data[option - 1]}
                </button>
                {renderResultMarker(option)}
            </div>
        </SlAnimation>)
    }

    function renderInput(option) {
        return (<textarea
            className={`mcq-option ${['one', 'two', 'three', 'four'][option - 1]} input`}
            onClick={() => handleOptionClick(option)}
            placeholder={`Add answer`}/>)
    }

    const renderOption = (option) => edit ? renderInput(option) : renderButton(option);

    return (<div className="mcq-options">
        {[1, 2, 3, 4].map((option) => (renderOption(option)))}
    </div>);
}