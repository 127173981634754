import React, {useState} from 'react';
import '../styles/components/TriviaCollectionGrid.css';
import SlAnimation from "@shoelace-style/shoelace/dist/react/animation";
import {modImageUrl} from "../utils/StringUtils";

export function TriviaCollectionGrid({data, onClick, className}) {
    const [playAnimation, setPlayAnimation] = useState(false);

    const clickAction = () => {
        setPlayAnimation(true);
        if (onClick) onClick();
    }
    return (
        <SlAnimation name="pulse" duration={500} iterations={1} play={playAnimation}
                     onSlFinish={() => setPlayAnimation(false)}>
            <div className={`trivia-collection-container ${className}`} onClick={clickAction}>
                <img src={modImageUrl(data?.image)} className="trivia-collection-image"/>
                <div className="trivia-collection-darken"/>
                <div className="trivia-collection-title">{data?.title}</div>
            </div>
        </SlAnimation>
    )
}
