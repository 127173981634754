import React, {useState} from 'react';
import '../styles/screens/TriviaFinalScoreboard.css';
import {TriviaScoreboardHeader} from "../components/TriviaScoreboardHeader";
import {ScoreboardUser} from "../components/ScoreboardUser";
import SlAnimation from "@shoelace-style/shoelace/dist/react/animation";
import {TopThreeUsers} from "../components/TopThreeUsers";
import {dummyImage} from "../utils/StringUtils";
import {Save, Share} from "@mui/icons-material";

export function TriviaFinalScoreboard() {
    const [activeAnimation, setActiveAnimation] = useState(null);

    function handleOptionClick(option) {
        setActiveAnimation(option);
    }

    return (
        <div className={`trivia-scoreboard-container`}>
            <TriviaScoreboardHeader className={`trivia-scoreboard-header`} title={`Final Scoreboard`}/>
            <div className={`trivia-scoreboard-content`}>

                <TopThreeUsers/>

                {Array.from({length: 50}, (_, i) => (
                    <ScoreboardUser
                        displayName={'John Doe'}
                        photoURL={dummyImage}
                        points={945}
                        rank={i + 4}
                        key={i}/>
                ))}
            </div>
            <div className={`trivia-scoreboard-footer`}>
                <SlAnimation name="pulse" duration={500} iterations={1} play={activeAnimation === 1}
                             onSlFinish={() => setActiveAnimation(null)}>
                    <button className="trivia-final-scoreboard-footer-button save" onClick={() => handleOptionClick(1)}>
                        <Save/>
                        Save
                    </button>
                </SlAnimation>

                <SlAnimation name="pulse" duration={500} iterations={1} play={activeAnimation === 2}
                             onSlFinish={() => setActiveAnimation(null)}>
                    <button className="trivia-final-scoreboard-footer-button share"
                            onClick={() => handleOptionClick(2)}>
                        <Share/>
                        Share
                    </button>
                </SlAnimation>
            </div>
        </div>
    )
}