import React, {useEffect, useState} from 'react'
import '../../styles/screens/details/CollectionDetail.css'
import {TriviaHeaderTwo} from "../../components/headers/TriviaHeaderTwo";
import {useLocation, useNavigate} from "react-router-dom";
import {SortHeaderOne} from "../../components/headers/SortHeaderOne";
import {getCategoryById, getCategoryQuizzes} from "../../utils/FirebaseManager";
import {TriviaQuizDetailCardOne} from "../../components/details/TriviaQuizDetailCardOne";
import {useInView} from "react-intersection-observer";

export function CollectionDetail() {
    const [collectionQuizzes, setCollectionQuizzes] = useState([]);
    let navigate = useNavigate();
    let collection = useLocation().state?.collection;
    const [collectionImage, setCollectionImage] = useState(collection?.image);
    let title = useLocation().pathname.split('/')[2];

    useEffect(() => {
        getCategoryQuizzes(collection?.title ?? title.replace('%20', ' ')).then(setCollectionQuizzes);
        if (!collection) {
            getCategoryById(title.replace('%20', ' ')).then(setCollectionImage);
        }
    }, []);

    return (
        <div className="collection-detail-container">
            <TriviaHeaderTwo title={collection?.title}/>
            <img src={collectionImage} className={`collection-detail-image`}/>
            <SortHeaderOne title={`${collectionQuizzes?.length ?? 0} Quizzes`} filterTitle={`Newest`}/>
            <div className="collection-detail-content">
                {collectionQuizzes && collectionQuizzes.map((item, index) => (
                    <LazyLoadImageTriviaCard item={item} key={index} navigate={navigate}/>
                ))}
            </div>
        </div>
    )
}

function LazyLoadImageTriviaCard({ item, navigate }) {
    //console.log("LazyLoadImageTriviaCard item: ", item);
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '100px 0px',
    });
    if (!item) return null;

    const handleClick = () => {
        navigate(`/quiz/${item.id}`, { state: { data: item } });
    };

    return (
        <div ref={ref} onClick={handleClick}>
            {inView ? <TriviaQuizDetailCardOne data={item} image={item?.image} /> : <div className="placeholder-card" style={{ height: '300px', background: '#eee' }}></div>}
        </div>
    );
}