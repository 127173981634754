import React from 'react';
import '../styles/components/TriviaScoreboardHeader.css';
import {Cross1Icon} from "@radix-ui/react-icons";
import {useNavigate} from "react-router-dom";

export function TriviaScoreboardHeader({title="Scoreboard", className}) {
    let navigate = useNavigate();
    function onBack() {
        navigate(-2);
    }
    return (
        <div className={`trivia-scoreboard-header ${className}`}>
            <Cross1Icon className={`trivia-scoreboard-header-close`} onClick={onBack}/>
            <p className="trivia-scoreboard-header-title">{title}</p>
            <p></p>
        </div>
    );
}