import React, {useState} from "react";
import '../../styles/screens/fragments/Profile.css';
import {TriviaAvatarCard} from "../../components/cards/TriviaAvatarCard";
import {TriviaHeaderFour} from "../../components/headers/TriviaHeaderFour";
import {SortHeaderOne} from "../../components/headers/SortHeaderOne";
import {dummyImage, dummyQuizListData} from "../../utils/StringUtils";
import {TriviaPillHeader} from "../../components/headers/TriviaPillHeader";

export function Profile() {
    const options = ['Quizzes', 'Collections', 'About'];
    const [activeSource, setActiveSource] = useState('quizzes');
    let data = {author: 'Adam Fils', authorSlug: 'adam_fils', authorUrl: dummyImage};

    return (
        <div className={`profile-source-container`}>
            <div className={`profile-source-top`}>
                <TriviaHeaderFour title={`Profile`} className={`profile-header-top`}/>
                <div className={`profile-header-image`}>
                    <div className={`profile-stats one`}>
                        <div className="profile-stats-item">
                            <p className="profile-stats-value">45</p>
                            <p className="profile-stats-title">Quizzes</p>
                        </div>
                        <div className={'profile-vertical-divider'}/>
                        <div className="profile-stats-item">
                            <p className="profile-stats-value">5.6M</p>
                            <p className="profile-stats-title">Plays</p>
                        </div>
                        <div className={'profile-vertical-divider'}/>
                        <div className="profile-stats-item">
                            <p className="profile-stats-value">16.8M</p>
                            <p className="profile-stats-title">Players</p>
                        </div>
                    </div>
                    <div className="profile-source-divider"/>
                    <div className={`profile-stats two`}>
                        <div className="profile-stats-item">
                            <p className="profile-stats-value">7</p>
                            <p className="profile-stats-title">Collections</p>
                        </div>
                        <div className={'profile-vertical-divider'}/>
                        <div className="profile-stats-item">
                            <p className="profile-stats-value">372.5K</p>
                            <p className="profile-stats-title">followers</p>
                        </div>
                        <div className={'profile-vertical-divider'}/>
                        <div className="profile-stats-item">
                            <p className="profile-stats-value">269</p>
                            <p className="profile-stats-title">following</p>
                        </div>
                    </div>
                </div>
                <TriviaAvatarCard data={data} actionName={`Edit Profile`}/>
                <TriviaPillHeader
                    activeSource={activeSource}
                    setActiveSource={setActiveSource}
                    options={options}
                    defaultValue={`quizzes`}/>
                <SortHeaderOne title={`45 Quizzes`} filterTitle={`Newest`}/>
            </div>
            <div className={`profile-source-list`}>
                {dummyQuizListData()}
            </div>
        </div>
    );
}