import React from 'react';
import '../styles/components/Playground.css';

export function Playground() {
    return (
        <div className="playground-container">
            <div className="playground-header">
                <h1>Playground Header</h1>
            </div>
            <div className="playground-content">
                <div className="playground-content-top">
                    <h1>Playground Content Top</h1>
                </div>
                <div className="playground-content-middle">
                    <h1>Playground Content Middle</h1>
                </div>
                <div className="playground-content-bottom">
                    <h1>Playground Content Bottom</h1>
                </div>
            </div>
            <div className="playground-footer">
                <h1>Playground Footer</h1>
            </div>
        </div>
    );
}