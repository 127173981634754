import React, {useState} from 'react';
import '../../styles/screens/fragments/CreateQuiz.css';
import {categories, dummyImage, modImageUrl} from "../../utils/StringUtils";
import TriviaSpinner from "../../components/TriviaSpinner";
import Sheet from "react-modal-sheet";
import {QuestionTypeGrid} from "../../components/QuestionTypeGrid";

export function CreateQuiz() {
    const visibilities = ['Public', 'Private'];
    const [category, setCategory] = useState('');
    const [visibility, setVisibility] = useState('Private');
    const [showQuestionSheet, setShowQuestionSheet] = useState(false);

    const handleAddQuestionClick = () => setShowQuestionSheet(true);
    const handleSheetClose = () => setShowQuestionSheet(false);

    return (
        <div className="create-quiz-container">
            <div className="create-quiz-content">
                <h2 className={`create-quiz-title`}>Create Quiz</h2>
                <img src={modImageUrl(dummyImage)} className="create-quiz-image" alt="quiz picture"/>
                <div className="create-quiz-inputs">
                    <label className="create-quiz-label">Title
                        <input className="create-quiz-input" placeholder="E.g Which US City Matches the Claim?"/>
                    </label>
                    <label className="create-quiz-label">Description
                        <textarea className="create-quiz-input description"
                                  placeholder="E.g In this quiz, you are going to find the US city that goes with the description."/>
                    </label>
                    <label className="create-quiz-label">Category
                        <TriviaSpinner dataList={categories} setLang={setCategory} dataName="Science"
                                       className="create-quiz-spinner"/>
                    </label>
                    <label className="create-quiz-label">Visibility
                        <TriviaSpinner dataList={visibilities} setLang={setVisibility} dataName="Public"
                                       className="create-quiz-spinner"/>
                    </label>
                    <label className="create-quiz-label">Tags
                        <input className="create-quiz-input" placeholder="E.g Chemistry, Covalent bonds, Science"/>
                    </label>
                </div>
            </div>
            <div className="create-quiz-footer">
                <button className="create-quiz-button save">Save</button>
                <button className="create-quiz-button add" onClick={handleAddQuestionClick}>Add Question</button>
            </div>
            <Sheet isOpen={showQuestionSheet}
                   snapPoints={[-50, 0.8]}
                   initialSnap={0}
                   onClose={handleSheetClose}>
                <Sheet.Container>
                    <Sheet.Header/>
                    <Sheet.Content>
                        <h2 className="create-quiz-sheet-title">Add Question</h2>
                        <QuestionTypeGrid/>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop/>
            </Sheet>
        </div>
    );
}