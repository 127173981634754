import React from 'react';
import '../styles/components/SettingItems.css';
import {dummyImage} from "../utils/StringUtils";
import {KeyboardArrowRight} from "@mui/icons-material";

export function SettingItems({title, onClick}) {

    return (
        <div>
            <div className={`setting-item-container`} onClick={onClick}>
                <div className={`setting-item-left`}>
                    <img src={dummyImage} className={`setting-item-img`}/>
                    <p className={`setting-item-title`}>{title}</p>
                </div>
                <KeyboardArrowRight className={`setting-item-right`}/>
            </div>
        </div>
    );
}