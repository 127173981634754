import '../../styles/components/headers/TriviaHeaderTwo.css';
import {BellIcon, MagnifyingGlassIcon, ArrowLeftIcon} from "@radix-ui/react-icons";
import {useNavigate} from "react-router-dom";

export function TriviaHeaderTwo({title, className, backAction}) {
    let navigate = useNavigate();
    const onBack = () => {
        if (backAction) {
            backAction();
        } else {
            navigate(-1);
        }
    }

    return (
        <div className={`homepage-header ${className}`}>
            <div className="homepage-header-left">
                <ArrowLeftIcon className="homepage-left-icon" onClick={onBack}/>
                <p className="homepage-app-name">{title}</p>
            </div>
            <div className="homepage-header-right">
                <MagnifyingGlassIcon className="homepage-header-icon search-icon"/>
                <BellIcon className="homepage-header-icon bell-icon"/>
            </div>
        </div>
    );
}