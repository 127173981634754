import firebase from 'firebase/compat/app';
import {getDatabase, onValue, ref} from "firebase/database";
import {getAnalytics} from "firebase/analytics";
import {onAuthStateChanged, getAuth, signOut, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {getMessaging, getToken} from "firebase/messaging";
import {createContext, useContext} from "react";

const firebaseConfig = {
    apiKey: "AIzaSyAV7i2kpGdDl0-aRl254s583th5dMyPDyU",
    authDomain: "tri-3via.firebaseapp.com",
    databaseURL: "https://tri-3via-default-rtdb.firebaseio.com",
    projectId: "tri-3via",
    storageBucket: "tri-3via.appspot.com",
    messagingSenderId: "164386755752",
    appId: "1:164386755752:web:9a0a645cd6a33702855e9d",
    measurementId: "G-DKQ5NFLFV7"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);

//export const messaging = getMessaging();

export const analytics = getAnalytics();

/*export function requestPermission() {
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            getToken(messaging, {vapidKey: "BMgjxFrVFc77TiFiYypeBDvgpD-G520CrNI-dPLN1f7FZl0kHMCa7c5P89UzWVUoosWGnA4OQWmcoEXVKcMKbqQ"}).then((currentToken) => {
                if (currentToken) {
                    console.log('Token: ', currentToken);
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
        } else {
            console.log('Unable to get permission to notify.');
        }
    });
}

export function subscribeToTopic(topic) {
    //console.log('Subscribing to topic: ', topic);
    getToken(messaging, {vapidKey: "BMgjxFrVFc77TiFiYypeBDvgpD-G520CrNI-dPLN1f7FZl0kHMCa7c5P89UzWVUoosWGnA4OQWmcoEXVKcMKbqQ"}).then((currentToken) => {
        if (currentToken) {
            console.log('Token: ', currentToken);
            messaging.subscribeToTopic(currentToken, topic).then(() => {
                console.log(`Subscribed to ${topic} topic`);
            }).catch((error) => {
                console.log('Error subscribing to topic: ', error);
            });
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}

export function unsubscribeFromTopic(topic) {
    //console.log('Unsubscribing from topic: ', topic);
    getToken(messaging, {vapidKey: "BMgjxFrVFc77TiFiYypeBDvgpD-G520CrNI-dPLN1f7FZl0kHMCa7c5P89UzWVUoosWGnA4OQWmcoEXVKcMKbqQ"}).then((currentToken) => {
        if (currentToken) {
            console.log('Token: ', currentToken);
            messaging.unsubscribeFromTopic(currentToken, topic).then(() => {
                console.log(`Unsubscribed from ${topic} topic`);
            }).catch((error) => {
                console.log('Error unsubscribing from topic: ', error);
            });
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}*/

export function logEvent(eventName, eventParams) {
    //console.log('Logging event: ', eventName, eventParams);
    analytics.logEvent(eventName, eventParams);
}

export function initializeFirebase() {
    return firebaseApp;
}

export const database = getDatabase();

export const currentUser = getAuth()?.currentUser;

export function signOutListener(navigate) {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (!currentUser) {
            // User is signed out
            navigate('/');
        }
    });
    return () => unsubscribe();
}

export function hepticFeedback(duration = 10) {
    navigator.vibrate(duration);
}

export function getFirebaseTimestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
}

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export async function checkProUser(uid, setUserIsPro) {
    //console.log("Checking Pro User: ", uid);
    if (!uid) return false;
    const path = `pro/${uid}`;
    //console.log("Path: ", path);
    const userRef = ref(database, path);
    onValue(userRef, (snapshot) => {
        //console.log(`Pro Data read ${snapshot.exists()}`);
        //return snapshot.exists();
        setUserIsPro(snapshot.exists());
    });
}


export const plans = [
    {
        image: 'https://cdn.midjourney.com/e8c07828-c9b5-427a-9d1b-020f7d368661/0_3.webp',
        title: 'Wyspa - Quick Pass',
        description: 'Dive into premium features with our 24-hour pass. Enjoy the full experience of our AI-driven interviews and personalized feedback. It’s the perfect sprint for your impending interview!',
        price: '$2.99',
        popular: false,
        monthly: false,
        duration: 1,
        actionLink: 'https://buy.stripe.com/fZebMoevl93KfWU6oo',
    },
    {
        image: 'https://cdn.midjourney.com/3dd5fc5c-4899-4ad6-86a6-1b319a101c60/0_2.webp',
        title: 'Wyspa - Pro Challenger',
        description: 'Elevate your practice with advanced features, including diverse mock interview scenarios and real-time feedback. Ideal for committed professionals.',
        price: '$4.99',
        popular: true,
        monthly: false,
        duration: 7,
        actionLink: 'https://buy.stripe.com/9AQeYAbj9bbSdOM8wx'
    },
    {
        image: 'https://cdn.midjourney.com/c9b07bc8-7a24-4ca4-97a8-e6bcc1297137/0_0.webp',
        title: 'Wyspa - Elite Performer',
        description: 'Gain a competitive edge with our full suite of premium features, including detailed analytics, personalized coaching, and priority support. The ultimate prep tool for serious candidates.',
        price: '$19.99',
        popular: false,
        monthly: true,
        duration: 30,
        actionLink: 'https://buy.stripe.com/dR6cQsdrh5Ry7qo002'
    },
];

export function getPlanDuration(duration) {
    if (duration === 1) {
        return 'One Time';
    } else if (duration === 7) {
        return 'Per Week';
    } else {
        return 'Per Month';
    }
}

export function getPlanGranularDuration(duration) {
    if (duration === 1) {
        return '24 Hours';
    } else if (duration === 7) {
        return '7 Days';
    } else {
        return '30 Days';
    }
}

export function getStreakCount(uid, setStreak) {
    //console.log("Getting Streak Count: ", uid);
    let path = `streaks/${uid}`;
    let streakRef = ref(database, path)
    //onlyOnce onValue
    onValue(streakRef, (snapshot) => {
        if (snapshot.exists()) {
            const {current} = snapshot.val();
            //console.log("Streak Count3: ", current);
            if (current > 1) {
                setStreak(current + " Days ");
            } else if (current === 1) {
                setStreak(current + " Day ");
            } else {
                setStreak("No ");
            }
            //return current;
        } else {
            //console.log("Streak Count not found");
            setStreak("No ");
            //return 0;
        }
    }, {
        onlyOnce: true
    });
}

const Logout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
}

export const googleSignIn = (user, setUser) => {
    if (user != null) {
        Logout();
        return;
    }
    const auth = getAuth();
    //auth.languageCode = 'it';
    auth.useDeviceLanguage();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        'login_hint': 'user@example.com'
    });
    signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            //const credential = GoogleAuthProvider.credentialFromResult(result);
            //const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            //subscribeToTopic(user.uid);
            // IdP data available using getAdditionalUserInfo(result)
            // console.log("User: ", user);
            // console.log("Token: ", token);
            // console.log("Credential: ", credential);
            setUser(user);
            //setLoginText("Logout");

            // ...
        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            /*console.log(error);
            console.log("Error Code: ", errorCode);
            console.log("Error Message: ", errorMessage);
            console.log("Email: ", email);
            console.log("Credential: ", credential);*/
        });
}
