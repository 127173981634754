import {TriviaQuizDetailCardOne} from "../components/details/TriviaQuizDetailCardOne";
import ManAvatar from "../assets/images/avatar/male.png";
import React from "react";
import {TriviaAvatar} from "../components/TriviaAvatar";

export const dummyImage = 'https://cdn.midjourney.com/5a09db8b-ef59-4742-b82a-4bb1698aa187/0_0.webp';
export const dummyFemaleImage = 'https://cdn.midjourney.com/5a09db8b-ef59-4742-b82a-4bb1698aa187/0_2.webp';
export const dummyMaleImage = 'https://cdn.midjourney.com/f8451809-6aba-4d2e-af74-348e2d3c35dd/0_1.webp';
export const categories = ['Science', 'Math', 'History', 'Geography', 'General Knowledge'];
export const questionTypes = ['Quiz', 'True or False', 'Puzzle', 'Type Answer', 'Quiz + Audio', 'Slider', 'Checkbox', 'Say the word', 'Poll', 'Drop Pin'];


export const dummyQuizListData = () => {
    return (
        <div>
            <TriviaQuizDetailCardOne data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: ManAvatar
            }}/>
            <TriviaQuizDetailCardOne data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: ManAvatar
            }}/>
            <TriviaQuizDetailCardOne data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: ManAvatar
            }}/>
            <TriviaQuizDetailCardOne data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: ManAvatar
            }}/>
            <TriviaQuizDetailCardOne data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: ManAvatar
            }}/>
            <TriviaQuizDetailCardOne data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: ManAvatar
            }}/>
            <TriviaQuizDetailCardOne data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: ManAvatar
            }}/>
            <TriviaQuizDetailCardOne data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: ManAvatar
            }}/>
            <TriviaQuizDetailCardOne data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: ManAvatar
            }}/>
        </div>
    )
}

export const dummyAuthorListData = () => {
    return (
        <div>
            <TriviaAvatar data={{
                title: "General Knowledge",
                description: "Test your",
                author: "Adam Fils",
                authorUrl: ManAvatar,
                image: dummyImage
            }}/>
        </div>
    )
}

export function formatNumber(number) {
    if (number >= 1000 && number < 1000000) {
        return (number / 1000).toFixed(1) + 'k';
    }
    if (number >= 1000000) {
        return (number / 1000000).toFixed(1) + 'M';
    }
    return number;
}

export function maskString(string, number = 20) {
    if (!string) return '';
    //console.log("String: ", string, " Number: ", number);
    if (number > string.length) {
        return string;
    }
    return string.substring(0, number) + '...';
}

export function timeAgo(time) {
    let seconds = Math.floor((new Date() - time) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " yr" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " month" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " day" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hr" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " min" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }
    return Math.floor(seconds) + " sec" + (Math.floor(seconds) > 1 ? 's' : '') + " ago";
}

export function extractAnswers(data) {
    if (!data) return [];
    let answers = [];
    let options = ['A', 'B', 'C', 'D'];
    for (let i = 0; i < 4; i++) {
        answers.push(data[options[i]]);
    }
    answers.sort(() => Math.random() - 0.5);
    return answers;
}

export function base64ToString(base64, trim = true) {
    let data = base64;
    if (trim) {
        data = base64.substring(1, base64.length - 1);
    }
    return atob(data);
}

export function modImageUrl(url) {
    if (!url?.startsWith('https://cdn.midjourney.com')) return url;
    let result = url.replace('.png', '_640_N.webp')
    if (url.includes('.webp')) {
        result = url.replace('.webp', '_640_N.webp')
    }
    return result;
    //return url.replace('.png', '_640_N.webp?method=shortest')
}