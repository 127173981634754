import '../styles/screens/Intro.css';
import Intro1 from '../assets/images/intro/intro1.png';
import Intro2 from '../assets/images/intro/intro2.png';
import Intro3 from '../assets/images/intro/intro3.png';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {googleSignIn, initializeFirebase} from "../utils/FirebaseUtils";
import {getAuth, onAuthStateChanged} from "firebase/auth";


initializeFirebase();

export function Intro() {
    let textArray = [
        <>
            Create, share and play <br/> quizzes whenever and <br/> wherever you want
        </>,
        <>
            Find fun and interesting <br/> quizzes to boost up your <br/> knowledge
        </>,
        <>
            Play and take quiz <br/> challenges together with <br/> your friends.
        </>
    ];
    let imageArray = [Intro1, Intro2, Intro3];
    const [activeImage, setActiveImage] = useState(imageArray[0]);
    const [activeText, setActiveText] = useState(textArray[0]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [user, setUser] = useState(null);

    let navigate = useNavigate();

    const nextSlide = () => {
        setActiveIndex(currentIndex => {
            let increment = currentIndex + 1;
            if (currentIndex >= 2) {
                return 0;
            } else {
                return increment;
            }
        });
    }

    const prevSlide = () => {
        setActiveIndex(currentIndex => {
            let decrement = currentIndex - 1;
            if (currentIndex <= 0) {
                return 2;
            } else {
                return decrement;
            }
        });
    }

    const showSlide = (index) => {
        setActiveIndex(index);
    }

    const showCreate = () => {
        //navigate('/register');
        //navigate('/home');
        googleSignIn(user, setUser);
    }

    const showLogin = () => {
        //navigate('/login');
        googleSignIn(user, setUser);
    }

    useEffect(() => {
        setActiveImage(imageArray[activeIndex]);
        setActiveText(textArray[activeIndex]);
    }, [activeIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide(); // Call nextSlide without passing activeIndex
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const auth = getAuth();
        // This listener gets called whenever the user's sign-in state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                // User is signed in
                setUser(currentUser);
                //subscribeToTopic(currentUser.uid);
                navigate('/')
                //console.log('User infox:', currentUser, currentUser.displayName);
            } else {
                // User is signed out
                setUser(null);
                console.log('User is signed out');
            }
        });

        // Clean up the subscription
        return () => unsubscribe();
    }, []);

    return (
        <div className="intro-parent">
            <div className="intro-parent-top">
                <img src={activeImage} alt="Fox" className="intro-img"/>
                <p className="intro-text">{activeText}</p>
                <div className="intro-dot-indicators">
                    <div className={activeIndex === 0 ? "intro-dot active" : "intro-dot"}
                         onClick={() => showSlide(0)}></div>
                    <div className={activeIndex === 1 ? "intro-dot active" : "intro-dot"}
                         onClick={() => showSlide(1)}></div>
                    <div className={activeIndex === 2 ? "intro-dot active" : "intro-dot"}
                         onClick={() => showSlide(2)}></div>
                </div>
            </div>
            <div className="intro-parent-bottom">
                <button className="intro-button get-started-button" onClick={showCreate}>GET STARTED</button>
                <button className="intro-button existing-user-button" onClick={showLogin}>I ALREADY HAVE AN ACCOUNT
                </button>
            </div>
        </div>
    );
}