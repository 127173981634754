// export const BASE_URL = "https://api.wyspa.app";

import {base64ToString} from "./StringUtils";

export const BASE_URL = "https://d32f-2605-6440-3008-a001-00-4473.ngrok-free.app";

let lastImageQuery = '';

// Helper function to handle fetch requests
async function fetchAPI(endpoint, method = 'GET', body = null) {
    const headers = {
        'Content-Type': 'application/json',
        'bypass-tunnel-reminder': 'true'
    };

    try {
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: method,
            headers: headers,
            body: body ? JSON.stringify(body) : undefined
        });

        if (!response.ok) {
            console.error(`${endpoint} Network response was not ok.`);
            return [];
        }

        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        }
        return await response.text();
    } catch (error) {
        console.error(`${endpoint} Fetch error:`, error);
        return [];
    }
}

// Fetch functions using the fetchAPI helper
export async function getImages(query) {
    if (query === '' || lastImageQuery === query) {
        return [];
    }
    lastImageQuery = query;
    return await fetchAPI(`/search_image?query=${query}`);
}

export async function getQuiz() {
    const jsonResponse = await fetchAPI('/get-quiz');
    return Array(20).fill(jsonResponse);
}

export async function getAuthor() {
    const jsonResponse = await fetchAPI('/get-author');
    return Array(20).fill(jsonResponse);
}

export async function getCollection() {
    const jsonResponse = await fetchAPI('/get-collection');
    return Array(20).fill(jsonResponse);
}

export async function postScore(data) {
    console.log("postScore data: ", data);
    const body = {
        uid: data?.uid,
        score: data?.score,
        quizId: data?.quizId,
        name: data?.name,
        time: new Date().getTime()
    };
    return await fetchAPI('/score', 'POST', body) !== [];
}