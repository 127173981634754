import React, {useEffect, useState} from 'react';
import '../styles/screens/TriviaSession.css';
import {TriviaSessionHeader} from "../components/headers/TriviaSessionHeader";
import SlProgressBar from '@shoelace-style/shoelace/dist/react/progress-bar';
import {TriviaResponseAlert} from "../components/TriviaResponseAlert";
import SlAnimation from "@shoelace-style/shoelace/dist/react/animation";
import {useLocation} from "react-router-dom";
import {TriviaQuizGridMCQ} from "../components/questions-active/TriviaQuizGridMCQ";
import {extractAnswers, modImageUrl} from "../utils/StringUtils";
import {TriviaScoreboard} from "./TriviaScoreboard";
import {postScore} from "../utils/ApiManager";
import {getAuth} from "firebase/auth";


const auth = getAuth();

function useQuizLifecycle(questions, setState) {
    useEffect(() => {
        setState(prev => ({
            ...prev,
            activeQuestion: prev.activeQuestion >= questions.length - 1 ? prev.activeQuestion : prev.activeQuestion + 1,
            progress: (prev.activeQuestion + 1) / questions.length * 100,
            visible: prev.activeQuestion < questions.length - 1
        }));
    }, [questions.length]);

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);
        window.history.pushState(null, '', window.location.href);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    function handleBeforeUnload(event) {
        const message = "Are you sure you want to leave? You may lose unsaved changes.";
        event.returnValue = message;
        return message;
    }

    function handlePopState(event) {
        const message = "Are you sure you want to leave? You may lose unsaved changes.";
        if (!window.confirm(message)) {
            window.history.pushState(null, '', window.location.href);
        }
    }
}

function useInitialUserData(setState) {
    useEffect(() => {
        const timer = setTimeout(() => {
            if (auth.currentUser) {
                setState(prev => ({
                    ...prev,
                    uid: auth.currentUser.uid,
                    name: auth.currentUser.displayName ?? 'Anonymous'
                }));
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
}

export function TriviaSession() {
    const data = useLocation()?.state?.data;
    const [state, setState] = useState({
        showBar: false,
        barCorrect: false,
        barMessage: '',
        activeQuestion: 0,
        selectedOption: null,
        question: {},
        answerOptions: null,
        progress: 0,
        answers: [],
        visible: true,
        points: 0,
        isFinalQuestion: false,
        uid: null,
        name: null
    });
    const questions = data?.questions ?? [];
    //Get quiz ID from url path
    const quizId = window.location.pathname.split('/').pop();

    // useInitialUserData(setState);
    // useQuizLifecycle(questions, setState);

    useEffect(() => {
        // Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1
        const isIphone = /iPhone/.test(navigator.userAgent);
        if (isIphone) {
            document.querySelector('.trivia-session-container').classList.add('iphone');
        }
    }, []);


    useEffect(() => {
        const {selectedOption, answerOptions} = state;
        if (selectedOption === null) return;
        const correct = selectedOption === answerOptions;
        const message = correct ? '+10 pts' : 'That was close';
        console.log(correct ? 'Correct Answer' : 'Incorrect Answer');

        setState(prev => ({
            ...prev, showBar: true, barCorrect: correct, barMessage: message,
            points: correct ? prev.points + 10 : prev.points
        }));
        setTimeout(() => nextQuestion(), 2000);
    }, [state.selectedOption]);

    useEffect(() => {
        const {activeQuestion} = state;
        //console.log('Active Question: ', activeQuestion);
        if (questions.length > 0) {
            const currentQuestion = questions[activeQuestion];
            const answers = extractAnswers(currentQuestion);
            const answerWord = currentQuestion[currentQuestion.Answer];

            setState(prev => ({
                ...prev, question: currentQuestion, answers, answerOptions: answerWord
            }));
        }
    }, [state.activeQuestion, questions]);

    useEffect(() => {
        if (state.isFinalQuestion) {
            //console.log('Final Question');
            postScore({
                uid: state.uid,
                score: state.points,
                quizId: data?.id,
                name: state.name
            });
        }
    }, [state.isFinalQuestion]);

    useEffect(() => {
        setTimeout(() => {
            //console.log('User ID ', state.uid);
            if (auth.currentUser) {
                setState(prev => ({
                    ...prev,
                    uid: auth.currentUser.uid,
                    name: auth.currentUser.displayName ?? 'Anonymous'
                }));
            }
        }, 1000);
    }, [state.uid]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = "Are you sure you want to leave? You may lose unsaved changes.";
            event.returnValue = message; // For most browsers
            return message; // For Chrome
        };

        const handlePopState = (event) => {
            const message = "Are you sure you want to leave? You may lose unsaved changes.";
            if (window.confirm(message)) {
                window.history.back(); // If user confirms, allow back navigation
            } else {
                window.history.pushState(null, '', window.location.href); // Otherwise, push state to prevent back navigation
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        // Push initial state to history
        window.history.pushState(null, '', window.location.href);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const nextQuestion = () => {
        const {activeQuestion} = state;
        const isFinalQuestion = activeQuestion >= questions.length - 1;
        const nextIndex = isFinalQuestion ? activeQuestion : activeQuestion + 1;
        const progress = (nextIndex + 1) / questions.length * 100;

        setState(prev => ({
            ...prev, activeQuestion: nextIndex, progress, visible: !isFinalQuestion, isFinalQuestion: isFinalQuestion
        }));
    }

    const {showBar, barCorrect, barMessage, progress, question, answers, answerOptions, visible} = state;

    return (<div className="trivia-session-container">
        {!state.isFinalQuestion ? (<div className="trivia-session-active">
            <TriviaResponseAlert
                showBar={showBar}
                setShowBar={(show) => setState(prev => ({...prev, showBar: show}))}
                correct={barCorrect} message={barMessage}/>
            <TriviaSessionHeader questionNumber={`${state.activeQuestion + 1}/${data?.count}`} points={state.points}/>
            <SlProgressBar value={progress} className="trivia-session-progress">{progress}%</SlProgressBar>
            <div className="trivia-session-content">
                <img src={modImageUrl(state?.question?.image ?? data?.image)} className="trivia-session-image" alt="profile"/>
                <p className="trivia-session-question">{question.Q}</p>
                <div className="quiz-details-divider"/>
                <TriviaQuizGridMCQ
                    question
                    data={answers}
                    answer={answerOptions}
                    setSelectedOption={(option) => setState(prev => ({
                        ...prev, selectedOption: option
                    }))}
                    isFinalQuestion={state.isFinalQuestion}
                />
            </div>
            {/*{(state.isFinalQuestion) && (
                <SlAnimation name="pulse" duration={500} iterations={1} play={true} onSlFinish={nextQuestion}>
                    <button className="trivia-session-footer-button">Next</button>
                </SlAnimation>)}*/}
        </div>) : (<TriviaScoreboard quizId={data?.id} uid={state.uid}/>)}
    </div>);
}
