import {database} from "./FirebaseUtils";
import {ScoreItem, TriviaItem, TriviaStats} from "../model/Quiz";
import {onValue, ref} from "firebase/database";
import {get} from "@firebase/database";

// Utility function to handle Firebase snapshot conversion and error logging
const fetchData = async (path, processData, fallback = {}) => {
    const refPath = ref(database, path);
    try {
        const snapshot = await get(refPath);
        const data = snapshot.val() ?? fallback;
        return processData(data);
    } catch (error) {
        console.error(`Error getting data from ${path}: `, error);
    }
};

// Convert quiz data to TriviaItem instances
const processQuizData = (data) => new TriviaItem(data);

// Convert data to TriviaItem instances and shuffle
const processDiscoverQuizData = (data) => {
    const result = Object.keys(data).map(key => new TriviaItem(data[key]));
    result.sort(() => Math.random() - 0.5);
    return result;
};

// Utility function to process score data
const processScoreData = (data) => {
    const result = Object.keys(data).map(key => new ScoreItem(data[key]));
    result.reverse();
    return result;
};

// Main function to fetch and handle quiz by ID
export async function getQuizById(quizId, listener = null, section = 'discover') {
    const result = await fetchData(`quiz/${quizId}`, processQuizData, {});
    if (listener) {
        listener(result, section);
    }
}

// Fetches all quizzes for the discover section and shuffles them
export async function getDiscoverQuiz(child) {
    //console.log('getDiscoverQuiz');
    return await fetchData(`quizzes/${child}`, processDiscoverQuizData, []);
}

// Continuously listen to score updates for a specific quiz
export function getScoreBoard(quizId, setScoreData) {
    const scoreRef = ref(database, `scores/${quizId}`);
    onValue(scoreRef, (snapshot) => {
        const data = snapshot.val() ?? [];
        setScoreData(processScoreData(data));
    }, (error) => {
        console.error(`Error listening to scores for quiz ${quizId}: `, error);
    });
}

// Fetch quiz statistics
export async function getQuizStats(quizId) {
    const result = await fetchData(`stats/${quizId}`, data => new TriviaStats(data), { played: 0, favorited: 0, shared: 0 });
    return result;
}

export async function getCategories() {
    //console.log('getCategories');
    const result = await fetchData('categories', data => data, []);
    return Object.values(result);
}

export async function getCategoryById(categoryId) {
    //console.log('getCategoryById');
    const result = await fetchData(`categories/${categoryId}`, data => data, {});
    return result?.image;
}

export async function getSubCategories(categoryId) {
    //console.log('getSubCategories');
    const result = await fetchData(`sub/${categoryId}`, data => data, []);
    return Object.values(result);
}

export async function getCategoryQuizzes(categoryId) {
    //console.log('getSubCategories: ', categoryId);
    const result = await fetchData(`quizzes/${categoryId}`, data => data, []);
    //console.log('getSubCategories result: ', result);
    return Object.values(result);
}

export async function getAuthors() {
    //console.log('getAuthors');
    const result = await fetchData('author', data => data, []);
    return Object.values(result);
}