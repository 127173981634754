import React from 'react';
import '../../styles/screens/settings/PersonalInfo.css';
import {TriviaHeaderTwo} from "../../components/headers/TriviaHeaderTwo";
import {dummyImage} from "../../utils/StringUtils";
import {Edit} from "@mui/icons-material";

export function PersonalInfo() {
    return (
        <div className={`personal-info-container`}>
            <TriviaHeaderTwo title={`Personal Info`}/>
            <div className={`personal-info-img-container`}>
                <img src={dummyImage} className={`personal-info-image`}/>
                <Edit className={`personal-info-edit`}/>
            </div>
            <label className="personal-info-label">Full Name
                <input className="personal-info-input" placeholder="John Doe" type={`text`}/>
            </label>
            <label className="personal-info-label">Email
                <input className="personal-info-input" placeholder="johndoe@gmail.com" type={`email`}/>
            </label>
            <label className="personal-info-label">Phone Number
                <input className="personal-info-input" placeholder="+1-300-555-0399" type={`tel`}/>
            </label>
            <label className="personal-info-label">Date of Birth
                <input className="personal-info-input" placeholder="John Doe" type={`date`}/>
            </label>
        </div>
    );
}