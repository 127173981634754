import React from 'react';
import '../../styles/screens/fragments/Join.css';
import {JoinHeader} from "../../components/headers/JoinHeader";

export function Join() {
    const options = ['Enter Pin', 'Scan QR Code'];
    const [activeSource, setActiveSource] = React.useState('enter-pin');

    function requestPictureCameraCapture() {
        const constraints = {
            video: true,
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                const video = document.querySelector('video');
                video.srcObject = stream;
                video.play();
            })
            .catch(error => {
                console.error('Error accessing media devices:', error);
            });
    }

    return (
        <div className={'join-container'}>
            <JoinHeader title={'Join Game'}/>
            <div className="join-source-list">
                {options.map((source, index) => {
                    return (
                        <div key={index}
                             className={`join-source ${activeSource === source.toLowerCase().replace(' ', '-') ? 'active' : ''}`}
                             onClick={() => setActiveSource(source.toLowerCase().replace(' ', '-'))}>{source}</div>
                    )
                })}
            </div>
            <input className="join-input" placeholder="ENTER PIN"
                   maxLength='6'
                   type={"number"}/>
            <button className="join-button">JOIN NOW</button>
        </div>
    );
}