import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../styles/screens/fragments/Home.css';
import {TriviaCardOne} from "../../components/cards/TriviaCardOne";
import {TriviaHeaderOne} from "../../components/headers/TriviaHeaderOne";
import {TriviaCardTwo} from "../../components/cards/TriviaCardTwo";
import {TriviaAvatar} from "../../components/TriviaAvatar";
import {TriviaCollection} from "../../components/TriviaCollection";
import {TriviaHeader} from "../../components/headers/TriviaHeader";
import {useNavigate} from "react-router-dom";
import {getAuthors, getCategories, getDiscoverQuiz} from "../../utils/FirebaseManager";
import {useInView} from "react-intersection-observer";

export function Home() {
    let navigate = useNavigate();
    const isInitialRender = useRef(true);
    const [state, setState] = useState({
        discoverQuiz: [],
        discoverAuthor: [],
        discoverCollection: [],
        discoverTrending: [],
        discoverTopPicks: []
    });

    const fetchData = useCallback(async () => {
        const fetchers = [
            getDiscoverQuiz('Geography').then(response => ({ key: 'discoverQuiz', data: response?.sort(() => Math.random() - 0.5) })),
            getDiscoverQuiz('Animals').then(response => ({ key: 'discoverTrending', data: response?.sort(() => Math.random() - 0.5) })),
            getDiscoverQuiz('Technology').then(response => ({ key: 'discoverTopPicks', data: response?.sort(() => Math.random() - 0.5) })),
            getCategories().then(response => ({ key: 'discoverCollection', data: response })),
            getAuthors().then(response => ({ key: 'discoverAuthor', data: response }))
        ];

        Promise.all(fetchers).then(results => {
            const newState = results.reduce((acc, result) => {
                acc[result.key] = result.data;
                return acc;
            }, {});
            setState(prev => ({ ...prev, ...newState }));
        }).catch(error => {
            console.error('Error fetching data', error);
        });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const showSearch = () => {
        navigate('/search');
    }
    const handleNavigate = (path, options) => navigate(path, options);

    const collectionClick = (item) => {
        //console.log("Collection Click: ", item);
        navigate('/collection/' + item.title, {state: {collection: item}});
    }


    return (
        <div className="home-fragment-parent">
            <TriviaHeader iconOneAction={() => handleNavigate('/search')} className="home-fragment-header"/>
            <div className={`home-fragment-content`}>
                <TriviaCardOne title="Play quiz together with your friends now!" actionTitle="Find Friends"/>
                <div className={`home-fragment-discover-parent ${state.discoverQuiz.length === 0 ? 'hidden' : ''}`}>
                    <TriviaHeaderOne title="Discover" actionTitle="View All" type={`quiz`} data={state.discoverQuiz}/>
                    <div className="home-fragment-horizontal-list quiz">
                        {state.discoverQuiz.map((quiz, index) => (
                            <LazyLoadImageHorizontal data={quiz} key={index}/>
                        ))}
                    </div>
                </div>
                <TriviaHeaderOne title="Top Authors" actionTitle="View All" type={`author`} data={state.discoverAuthor}/>
                <div className="home-fragment-horizontal-list avatar">
                    {state.discoverAuthor.map((author, index) => (
                        <TriviaAvatar data={author} key={index}/>
                    ))}
                </div>
                <TriviaHeaderOne title="Top Collections" actionTitle="View All" type={`collection`} data={state.discoverCollection}/>
                <div className="home-fragment-horizontal-list collection">
                    {state.discoverCollection.map((collection, index) => (
                        <LazyLoadImageHorizontalCollection data={collection} key={index} onClick={()=> collectionClick(collection)}/>
                    ))}
                </div>
                <div className={`home-fragment-trending-parent ${state.discoverTrending.length === 0 ? 'hidden' : ''}`}>
                    <TriviaHeaderOne title="Trending Quiz" actionTitle="View All" type={`quiz`} data={state.discoverTrending}/>
                    <div className="home-fragment-horizontal-list quiz">
                        {state.discoverTrending.map((quiz, index) => (
                            <LazyLoadImageHorizontal data={quiz} key={index}/>
                        ))}
                    </div>
                </div>
                <div className={`home-fragment-trending-parent ${state.discoverTopPicks.length === 0 ? 'hidden' : ''}`}>
                    <TriviaHeaderOne title="Top Picks" actionTitle="View All" type={`quiz`} data={state.discoverTopPicks}/>
                    <div className="home-fragment-horizontal-list quiz">
                        {state.discoverTopPicks.map((quiz, index) => (
                            <LazyLoadImageHorizontal data={quiz} key={index}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function LazyLoadImageHorizontal({ data }) {
    // Adjust rootMargin for horizontal list: '0px 100px 0px 100px' means 100px from left and right
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '0px 50px 0px 50px'  // Top, right, bottom, left margins
    });

    if (!data) return null;  // Handling cases where data might be undefined or null

    return (
        <div ref={ref}>
            {/*Ensure each card has a defined width in a horizontal list*/}
            {inView ? <TriviaCardTwo data={data}/> : <div className="placeholder-card" style={{ height: '200px', minWidth: '200px', background: '#eee' }}></div>}
        </div>
    );
}

function LazyLoadImageHorizontalCollection({ data, onClick }) {
    // Adjust rootMargin for horizontal list: '0px 100px 0px 100px' means 100px from left and right
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '0px 50px 0px 50px'  // Top, right, bottom, left margins
    });

    if (!data) return null;  // Handling cases where data might be undefined or null

    return (
        <div ref={ref}>
            {/*Ensure each card has a defined width in a horizontal list*/}
            {inView ? <TriviaCollection data={data} onClick={onClick}/> : <div className="placeholder-card" style={{ height: '200px', minWidth: '200px', background: '#eee' }}></div>}
        </div>
    );
}