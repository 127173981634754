import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import '../styles/components/TriviaSpinner.css';
import {useEffect, useState} from "react";

export default function TriviaSpinner({setLang, dataList, dataName, className}) {
    const [data, setData] = useState(dataName);

    const handleChange = (event) => {
        let value = event.target.value.toString();
        //console.log(value)
        setData(value);
    };
    useEffect(() => {
        setLang(data)
    }, [data, setLang])

    return (
        <Box sx={{minWidth: 120}}>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data}
                    onChange={handleChange}
                    className={className}>

                    {(dataList.length > 0) ? dataList.map((d, index) => (
                        <MenuItem value={d} key={index}>{d}</MenuItem>
                    )) : <MenuItem value={''}>No Data</MenuItem>}
                </Select>
            </FormControl>
        </Box>
    );
}
