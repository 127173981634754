import React, {useEffect, useState} from 'react';
import '../styles/components/TriviaResponseAlert.css';
import SlAnimation from '@shoelace-style/shoelace/dist/react/animation';

export function TriviaResponseAlert({showBar, setShowBar, correct = false, message = "+945 pts", duration = 2000}) {
    const [show, setShow] = useState(false);
    const [playAnimation, setPlayAnimation] = useState(false);

    const errorMessages = ['That was close', 'Fyuhhh.. That was close', 'Almost there', 'Better luck next time', 'You can do it!'];
    //let errorMessage = errorMessages[Math.floor(Math.random() * errorMessages.length)];
    let displayMessage = correct ? message : errorMessages[1];

    useEffect(() => {
        if (showBar) {
            setShow(true);
            setPlayAnimation(true);

            const timer = setTimeout(() => {
                setShow(false);
                setPlayAnimation(false); // Ensure animation stops after it's played
                setShowBar(false);
            }, duration);

            return () => clearTimeout(timer);
        }
    }, [showBar, duration]); // Include all variables that effect depends on

    return (
        <SlAnimation name="slideInDown" duration={500} iterations={1} play={playAnimation}
                     onSlFinish={() => setPlayAnimation(false)}>
            <div className={`trivia-response-alert ${correct ? "correct" : "incorrect"} ${show ? '' : 'hidden'}`}>
                <div className={`trivia-response-content`}>
                    <h1 className="trivia-response-alert-title">{correct ? "Correct!" : "Incorrect"}</h1>
                    <p className={`trivia-response-alert-description ${correct ? "correct" : "incorrect"}`}>{displayMessage}</p>
                </div>
            </div>
        </SlAnimation>
    );
}
