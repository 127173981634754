import React, {useState} from 'react';
import '../styles/screens/TriviaCreateSession.css';
import {TriviaSessionHeader} from "../components/headers/TriviaSessionHeader";
import SlProgressBar from '@shoelace-style/shoelace/dist/react/progress-bar';
import {TriviaResponseAlert} from "../components/TriviaResponseAlert";
import {TriviaQuizGridMCQ} from "../components/questions-active/TriviaQuizGridMCQ";
import {TriviaQuizGridTrueOrFalse} from "../components/questions-active/TriviaQuizGridTrueOrFalse";
import {TriviaQuizListMCQ} from "../components/questions-active/TriviaQuizListMCQ";
import {TriviaQuizListDraggable} from "../components/questions-active/TriviaQuizListDraggable";
import SlAnimation from "@shoelace-style/shoelace/dist/react/animation";
import {TriviaScoreboard} from "./TriviaScoreboard";
import {categories, dummyImage, questionTypes} from "../utils/StringUtils";
import {TriviaFinalScoreboard} from "./TriviaFinalScoreboard";
import TriviaSpinner from "../components/TriviaSpinner";
import {TriviaCover} from "./TriviaCover";

export function TriviaCreateSession() {
    const value = 50;
    const [activeAnimation, setActiveAnimation] = useState(null);
    const [activeQuiz, setActiveQuiz] = useState('Quiz');
    const [selectedImage, setSelectedImage] = useState(null);
    let visible = true;

    function handleOptionClick(option) {
        setActiveAnimation(option);
    }

    return (
        <div className="trivia-session-container">
            <div className={`trivia-session-active ${visible ? '' : 'hidden'}`}>
                <TriviaResponseAlert correct={false} message={'+945 pts'}/>
                <TriviaSessionHeader/>
                <SlProgressBar value={value} className={`trivia-session-progress`}>{value}%</SlProgressBar>
                <div className="trivia-session-content">
                    <img src={dummyImage} className={`trivia-session-image`} alt={'profile picture'}/>
                    <div className={`trivia-create-session-time-group`}>
                        <div className={`trivia-create-session-time-group-item time`}>10 sec</div>
                        <div className={`trivia-create-session-time-group-item points`}>100pt</div>
                        <div className={`trivia-create-session-time-group-item-blank`}></div>
                        <TriviaSpinner dataList={questionTypes} dataName={`Quiz`} setLang={setActiveQuiz} className={`trivia-create-session-time-group-item`}/>
                    </div>
                    <input className={`trivia-create-session-question`} placeholder={`Tap to add question`}/>
                    <div className="quiz-details-divider"/>
                    <TriviaQuizGridMCQ edit={true}/>
                </div>
                <div className="trivia-session-footer">
                    <SlAnimation name="pulse" duration={500} iterations={1} play={activeAnimation !== null}
                                 onSlFinish={() => setActiveAnimation(null)}>
                        <button className="trivia-session-footer-button" onClick={() => handleOptionClick(1)}>Next
                        </button>
                    </SlAnimation>
                </div>
            </div>
            <div className={`trivia-session-inactive ${visible ? 'hidden' : ''}`}>
                <TriviaCover />
            </div>
        </div>
    );
}