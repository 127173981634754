import React, {useState} from 'react'
import '../../styles/screens/details/ViewAll.css'
import {TriviaHeaderTwo} from "../../components/headers/TriviaHeaderTwo";
import {useLocation, useNavigate} from "react-router-dom";
import {TriviaVerticalList} from "../../components/lists/TriviaVerticalList";
import {dummyQuizListData} from "../../utils/StringUtils";
import {TriviaAvatarCard} from "../../components/cards/TriviaAvatarCard";
import {TriviaCollectionGrid} from "../../components/TriviaCollectionGrid";
import {TriviaQuizDetailCardOne} from "../../components/details/TriviaQuizDetailCardOne";

export function ViewAll() {
    const title = useLocation().state?.title;
    const data = useLocation().state?.data;
    //const category = useLocation().state?.category;
    const [categoryItems, setCategoryItems] = useState([]);
    const type = useLocation().state?.type;
    let navigation = useNavigate();
    //console.log("Type: ", type)
    if (!title) {
        // Redirect to the homepage if the title is not available
        navigation(-1);
    } else {
        console.log(title);
    }

    const collectionClick = (item) => {
        //console.log("Collection Click: ", item);
        navigation('/collection/' + item.title, {state: {collection: item}});
    }

    const renderCategory = () => {
        return (
            <div className="viewall-content collection-grid">
                {data && data.map((item, index) => (
                    <TriviaCollectionGrid key={index} data={item} className={"viewall-collection-body"} onClick={()=> collectionClick(item)}/>
                ))}
            </div>
        )
    }

    const renderAuthor = () => {
        return (
            <div className="viewall-content">
                {Array.from({length: 40}, (_, i) => (
                    <TriviaAvatarCard className={"viewall-body"} actionName={'Follow'}/>
                ))}
            </div>
        )
    }

    const renderQuiz = () => {
        return (
            <div className="viewall-content">
                <div className="collection-detail-content">
                    {data && data.map((item, index) => (
                        <TriviaQuizDetailCardOne key={index} data={item} image={item?.image} index={index}
                                                 onClick={() => navigation(`/quiz/${item.id}`, {state: {data: item}})}/>
                    ))}
                </div>
            </div>
        )
    }

    const renderView = () => {
        switch (type) {
            case 'quiz':
                return renderQuiz();
            case 'author':
                return renderAuthor();
            case 'collection':
                return renderCategory();
            default:
                return renderCategory();
        }
    }

    return (
        <div className={`viewall-parent`}>
            <TriviaHeaderTwo title={title} className="viewall-header"/>
            {renderView()}
        </div>
    )
}