import React, {useState} from "react";
import '../../styles/components/details/TriviaQuizDetailCardOne.css';
import SlAnimation from '@shoelace-style/shoelace/dist/react/animation';
import {maskString, modImageUrl, timeAgo} from "../../utils/StringUtils";

export function TriviaQuizDetailCardOne({data, image, onClick}) {
    const [playAnimation, setPlayAnimation] = useState(false);
    //console.log("TriviaQuizDetailCardOne data: ", data);

    const clickAction = () => {
        setPlayAnimation(true);
        if (onClick) onClick();
    }

    return (
        <SlAnimation name="pulse" duration={1000} iterations={1} play={playAnimation}
                     onSlFinish={() => setPlayAnimation(false)}>
            <div className="trivia-quiz-detail-card-one" onClick={clickAction}>
                <img src={`${modImageUrl(data?.image ? data?.image : image)}`} alt="quiz" className="trivia-quiz-detail-card-one-image"/>
                <div className="trivia-quiz-detail-card-one-content">
                    <div className="trivia-quiz-detail-card-one-title">{data?.title}</div>
                    <div className="trivia-quiz-detail-card-months-plays">
                        <div className="trivia-quiz-detail-card-one-months">{timeAgo(data?.modified)}</div>
                        <div className="trivia-quiz-detail-card-one-dot"></div>
                        <div className={`trivia-quiz-detail-card-one-plays ${data?.difficulty}`}>{data?.difficulty}</div>
                        <div className="trivia-quiz-detail-card-one-dot"></div>
                        <div className="trivia-quiz-detail-card-one-plays">{maskString(data?.category, 13)}</div>
                    </div>
                    <div className="trivia-quiz-detail-card-one-author-parent">
                        <img src={data?.authorUrl} alt="avatar" className="trivia-quiz-detail-card-one-author-avatar"/>
                        <div className="trivia-quiz-detail-card-one-author">{data?.author}</div>
                    </div>
                </div>
            </div>
        </SlAnimation>
    );
}