import React from "react";
import '../../styles/components/cards/TriviaCardOne.css';

export function TriviaCardOne({title, actionTitle}) {
    return (
        <div className={`trivia-card-one-container`}>
            <div className="trivia-card-one-title">{title}</div>
            <div className="trivia-card-one-action">{actionTitle}</div>
        </div>
    )
}