import '../../styles/components/headers/TriviaHeader.css';
import ClearLogo from "../../assets/images/logo192.webp";
import {BellIcon, MagnifyingGlassIcon} from "@radix-ui/react-icons";

export function TriviaHeader({iconOneAction, iconTwoAction, className}) {
    return (
        <div className={`homepage-header ${className}`}>
            <div className="homepage-header-left">
                <img src={ClearLogo} alt="Logo" className="homepage-clear-logo"/>
                <p className="homepage-app-name">3Via</p>
            </div>
            <div className="homepage-header-right">
                <MagnifyingGlassIcon className="homepage-header-icon search-icon" onClick={iconOneAction}/>
                <BellIcon className="homepage-header-icon bell-icon" onClick={iconTwoAction}/>
            </div>
        </div>
    );
}