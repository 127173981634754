import React from "react";
import '../../styles/components/headers/TriviaPillHeader.css';

export function TriviaPillHeader({options, defaultValue=null, className, activeSource, setActiveSource}) {

    return (
        <div className={`trivia-pill-container ${className}`}>
            <div className="trivia-cover-source-list">
                {options.map((source, index) => {
                    return (
                        <div key={index}
                             className={`trivia-cover-source ${activeSource === source.toLowerCase().replace(' ', '-') ? 'active' : ''}`}
                             onClick={() => setActiveSource(source.toLowerCase().replace(' ', '-'))}>{source}</div>
                    )
                })}
            </div>
        </div>
    );
}