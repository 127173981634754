import * as React from "react";
import '../styles/components/TriviaBottomBar.css';
import BottomBarTwo from "./BottomBarTwo";

export function TriviaBottomBar({bottomIndex, setBottomIndex, className}) {
    return (
        <div className={className}>
            <BottomBarTwo activeTab={bottomIndex} setActiveTab={setBottomIndex}/>
        </div>
    )
}