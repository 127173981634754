import React, {useState} from 'react';
import '../../styles/components/cards/TriviaCardTwo.css';
import SlAnimation from "@shoelace-style/shoelace/dist/react/animation";
import {useNavigate} from "react-router-dom";
import {modImageUrl} from "../../utils/StringUtils";

export function TriviaCardTwo({data, onClick}) {
    const [playAnimation, setPlayAnimation] = useState(false);
    let navigate = useNavigate();
    //console.log("TriviaCardTwo data: ", data);

    const clickAction = () => {
        setPlayAnimation(true);
        //if (onClick) onClick();
        navigateToDetails();
    }

    const navigateToDetails = () => {
        navigate(`/quiz/${data.id}`, {state: {data: data}});
    }

    return (
        <SlAnimation name="pulse" duration={500} iterations={1} play={playAnimation}
                     onSlFinish={() => setPlayAnimation(false)}>
            <div className={`trivia-card-two-container`} onClick={clickAction}>
                <img src={modImageUrl(data?.image)} className="trivia-card-two-image"/>
                <p className="trivia-card-two-quiz-count">{data?.count} Qs</p>
                <div className="trivia-card-two-title">{data?.title}</div>
                <div className="trivia-card-two-author-parent">
                    <img src={modImageUrl(data?.authorUrl)} alt="avatar" className="trivia-card-two-author-avatar"/>
                    <div className="trivia-card-two-author">{data?.author}</div>
                    <div className={`trivia-quiz-detail-card-one-plays ${data.difficulty}`}>{data.difficulty}</div>
                </div>
            </div>
        </SlAnimation>
    )
}