import React from 'react';
import '../styles/components/PodiumUser.css';

export function PodiumUser({displayName, photoURL, points, isUser=false, className}) {
    return (
        <div className={`podium-user-container ${isUser ? 'user': ''} ${className}`}>
            <img className={`podium-user-image`} src={photoURL} alt="profile picture"/>
            <p className={`podium-user-name`}>{displayName}</p>
            <p className={`podium-user-points`}>{points}</p>
        </div>
    );
}