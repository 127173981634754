export class TriviaItem {
    constructor(data) {
        this.id = data?.id;
        this.title = data?.title;
        this.description = data?.description;
        this.difficulty = data?.difficulty;
        this.author = data?.author;
        this.authorUrl = data?.authorUrl;
        this.authorSlug = data?.authorSlug;
        this.authorId = data?.authorId;
        this.image = data?.image;
        this.count = data?.count;
        this.categoryId = data?.categoryId;
        this.category = data?.category;
        this.created = new Date(data?.created ?? 0);
        this.modified = new Date(data?.modified ?? 0);
        this.tags = data?.tags ?? [];
        this.questions = data?.questions ?? [];
    }
}

export class ScoreItem {
    constructor(data) {
        this.uid = data?.uid;
        this.name = data?.name;
        this.image = data?.image;
        this.score = data?.score;
        this.time = data?.time;
    }
}

export class TriviaStats {
    constructor(data) {
        this.played = data?.played ?? 0;
        this.favorited = data?.favorited ?? 0;
        this.shared = data?.shared ?? 0;
    }
}